import React from "react"
import "../styles/styles.scss"

const Modal = () => {
    return(
        <div className="hero-demo">
            <div className="container">
              <div className='column'>
                <div>
                <div className="main-text"> Vraag een <span>demo aan.</span></div>
                <div className="sub-text">  Wil je meer weten over wat onze smart software kan doen voor je proces?</div>
                </div>
            </div>
            </div>
            </div>
    )
}

export default Modal