import React from "react"
import "../styles/styles.scss"

import Header from "../components/header"
import Footer from "../components/footer"
import Modal from "../components/modal"

import {Helmet} from 'react-helmet'


const IndexPage = () => (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Skialabs. Vraag een demo aan. </title>
        <description>Vraag een demo aan van het smart waste platform.</description>
      </Helmet>
      <Header />
      <Modal />
      <Footer />
    </div>
)

export default IndexPage
